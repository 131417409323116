




import Vue from 'vue'
import TenderForm from '@/views/Tender/views/TenderForm.vue'

export default Vue.extend({
  name: 'CreateTender',
  components: { TenderForm },
})
