
























































































































































import Vue from 'vue'
import { outgoingDirection, ingoingDirection } from '@/constants/capacityDirections'
import CancelButton from '@/components/buttons/CancelButton.vue'
import SubmitButton from '@/components/buttons/SubmitButton.vue'
import CreateButton from '@/components/buttons/CreateButton.vue'
import CardTitle from '@/components/CardTitle.vue'
import { LotDTO, TimeIntervalDTO } from '@/services/tender/types'
import DatePicker from '@/components/DatePicker.vue'
import TimePicker from '@/components/TimePicker.vue'
import moment from 'moment'
import { isJsonString, today } from '@/utils/helpers'
import EditButton from '@/components/buttons/EditButton.vue'
// import VueTimepicker from '@/components/VueTimepicker.vue'

const validInterval = (value: TimeIntervalDTO) => {
  const startTime = moment(value.startTime, 'HH:mm')
  const endTime = moment(value.endTime, 'HH:mm')
  const duration = moment.duration(endTime.diff(startTime))
  const durationAsHours = Number(duration.asHours().toFixed(2))
  return durationAsHours > 0
}

export default Vue.extend({
  name: 'LotForm',
  components: {
    // VueTimepicker,
    EditButton,
    TimePicker,
    CardTitle,
    CreateButton,
    DatePicker,
    SubmitButton,
    CancelButton,
  },
  props: {
    type: { type: String },
    viewType: { type: String, default: 'create' },
    lot: {
      type: Object as () => Omit<
        LotDTO,
        'id' | 'isConditional' | 'termsAndConditions' | 'conditions' | 'totalLotHours'
      >,
      default: () => ({
        power: NaN,
        direction: '[]',
        startDate: '',
        endDate: '',
        cbcCoverage: true,
        crossBorderCapacityId: NaN,
        crossBorderCapacity: null,
        deliveryProfile: [{ startTime: '', endTime: '' }],
      }),
    },
  },
  data(): {
    fullDayInterval: boolean
    dialog: boolean
    data: Omit<LotDTO, 'id' | 'isConditional' | 'termsAndConditions' | 'conditions' | 'totalLotHours'>
    outgoingDirection: typeof outgoingDirection
    ingoingDirection: typeof ingoingDirection
    rules: {
      required: (value: string) => string | boolean
      validInterval: (value: TimeIntervalDTO) => string | boolean
    }
  } {
    return {
      fullDayInterval: false,
      dialog: false,
      rules: {
        required: (value: string) => !!value || this.$tc('validation.required'),
        validInterval,
      },
      data: {
        name: '',
        power: NaN,
        direction: '[]',
        startDate: '',
        endDate: '',
        cbcCoverage: true,
        crossBorderCapacityId: NaN,
        crossBorderCapacity: null,
        deliveryProfile: [{ startTime: '', endTime: '' }],
      },
      outgoingDirection,
      ingoingDirection,
    }
  },
  computed: {
    isCreateMode(): boolean {
      return this.viewType === 'create'
    },
    isEditMode(): boolean {
      return this.viewType === 'edit'
    },
    directions: {
      get(): string {
        const direction = JSON.parse(
          isJsonString(this.data.direction as string) ? (this.data.direction as string) : '[]',
        )

        return direction.length > 0 ? direction[0] : ''
      },
      set(value: string[]): void {
        this.data.direction = JSON.stringify([value])
      },
    },
    quantity(): number {
      if (this.data.power <= 0 || !this.data.startDate || !this.data.endDate || this.data.startDate > this.data.endDate)
        return 0

      const startDate = moment(this.data.startDate, 'YYYY-MM-DD')
      const endDate = moment(this.data.endDate, 'YYYY-MM-DD')
      const dateDuration = moment.duration(endDate.diff(startDate))
      const days = dateDuration.asDays()

      let hours = 0
      this.data.deliveryProfile.forEach((timeInterval) => {
        if (!timeInterval.startTime || !timeInterval.endTime) return
        const startTime = moment(timeInterval.startTime, 'HH:mm')
        const endTime = moment(timeInterval.endTime, 'HH:mm')
        const duration = moment.duration(endTime.diff(startTime))
        const durationAsHours = Number(duration.asHours().toFixed(2))

        if (durationAsHours < 0) return

        hours = hours + durationAsHours
      })

      return (days + 1) * hours * this.data.power
    },
  },
  methods: {
    today,
    setDirectionIfNoCoverage(hasCbcCoverage: boolean): void {
      if (!hasCbcCoverage) this.data.direction = ''
    },
    getLot(): void {
      if (this.lot?.deliveryProfile.length === 1) {
        const [{ startTime, endTime }] = this.lot?.deliveryProfile
        if (startTime === '00:00' && endTime === '24:00') this.fullDayInterval = true
      }
      this.data = JSON.parse(JSON.stringify(this.lot))
    },
    async add(): Promise<void> {
      if (!(this.$refs.form as Vue & { validate: () => boolean }).validate()) return
      let badInterval = false
      this.data.deliveryProfile.forEach(({ startTime, endTime }) => {
        if (!startTime || !endTime || !validInterval({ startTime, endTime })) badInterval = true
      })

      if (badInterval) {
        this.$toast.error(this.$tc('invalidInterval'))
        return
      }

      this.$emit('set', {
        ...this.data,
        quantity: this.quantity,
        crossBorderCapacityId: this.data.crossBorderCapacity ? this.data.crossBorderCapacity.id : null,
      })
      this.close()
    },
    close(): void {
      this.resetData()
      this.dialog = false
      this.fullDayInterval = false
    },
    resetData(): void {
      this.data = {
        name: '',
        power: NaN,
        startDate: '',
        endDate: '',
        direction: '[]',
        cbcCoverage: true,
        crossBorderCapacityId: NaN,
        crossBorderCapacity: null,
        deliveryProfile: [{ startTime: '', endTime: '' }],
      }
      ;(this.$refs.form as Vue & { resetValidation: () => void }).resetValidation()
    },
    addTimeInterval() {
      this.data.deliveryProfile.push({ startTime: '', endTime: '' })
    },
    removeTimeInterval(key: number) {
      this.data.deliveryProfile.splice(key, 1)
    },
  },
  watch: {
    fullDayInterval() {
      if (this.fullDayInterval) this.data.deliveryProfile = [{ startTime: '00:00', endTime: '24:00' }]
      if (!this.fullDayInterval) this.data.deliveryProfile = [{ startTime: '', endTime: '' }]
      this.$forceUpdate()
    },
  },
})
