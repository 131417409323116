

























































import { LotDTO } from '@/services/tender/types'
import LotForm from '@/views/Tender/Lot/LotForm.vue'
import { formatTime, formatDate } from '@/utils/helpers'
import RemoveButton from '@/components/buttons/RemoveButton.vue'
import { useErrors } from '@/components/composables/useErrors'
import { defineComponent } from '@vue/composition-api'
import { parseAndJoin } from '@/utils/helpers'
import DeleteButton from '@/components/buttons/DeleteButton.vue'

export default defineComponent({
  name: 'LotsTable',
  setup() {
    const { errors, clearFieldError } = useErrors()
    return { errors, clearFieldError }
  },
  components: { DeleteButton, LotForm, RemoveButton },
  props: {
    lots: { type: Array as () => LotDTO[], required: true },
    type: { type: String, required: true },
    service: { type: Object, default: () => ({}) },
  },
  computed: {
    computedLots: {
      get(): LotDTO[] {
        return this.lots
      },
      set(value: LotDTO[]): void {
        this.$emit('input', value)
      },
    },
  },
  model: {
    prop: 'lots',
  },
  methods: {
    formatTime,
    formatDate,
    parseAndJoin,
    async add(data: LotDTO) {
      this.computedLots.push(data)
      await this.syncLotsNames()
    },
    async update(value: LotDTO, index: number) {
      if (this.computedLots.length === 1) {
        this.computedLots.push(value)
        return this.remove(index)
      }
      await this.remove(index)
      this.computedLots.splice(index, 0, value)
      await this.syncLotsNames()
    },
    async remove(index: number): Promise<void> {
      this.computedLots.splice(index, 1)
      await this.syncLotsNames()
    },
    async syncLotsNames(): Promise<void> {
      this.computedLots.forEach((lot, index) => (lot.name = `LOT ${index + 1}`))
    },
  },
})
