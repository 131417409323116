


















































































































































import CancelButton from '@/components/buttons/CancelButton.vue'
import SubmitButton from '@/components/buttons/SubmitButton.vue'
import CardTitle from '@/components/CardTitle.vue'
import TenderService from '@/services/tender'
import DatePicker from '@/components/DatePicker.vue'
import { TenderDTO } from '@/services/tender/types'
import LotsTable from '@/views/Tender/Lot/LotsTable.vue'
import TenderStatus from '@/views/Tender/partials/TenderStatus.vue'
import { errorMessages } from '@/utils/helpers'
import TimePicker from '@/components/TimePicker.vue'
import { VueEditor } from 'vue2-editor'
import TabsSwitcher from '@/components/TabsSwitcher.vue'
import { TranslateResult } from 'vue-i18n'
import { defineComponent } from '@vue/composition-api'
import { useErrors } from '@/components/composables/useErrors'
import { today } from '@/utils/helpers'

export default defineComponent({
  name: 'TenderForm',
  setup() {
    const { errors, clearFieldError } = useErrors()
    return { errors, clearFieldError, TenderService }
  },
  components: {
    TabsSwitcher,
    LotsTable,
    DatePicker,
    CardTitle,
    SubmitButton,
    CancelButton,
    TenderStatus,
    TimePicker,
    VueEditor,
  },
  data: function (): {
    dialog: boolean
    data: Omit<TenderDTO, 'hasOwnBids'>
    tabs: TranslateResult[]
    creatorName: string
    today: () => string
  } {
    return {
      dialog: false,
      today,
      creatorName: '',
      data: {
        id: 0,
        name: '',
        description: '',
        publishDate: '',
        endDate: '',
        publishTime: '',
        endTime: '',
        type: this.$route.params.type || '',
        status: '',
        lots: [],
        termsAndConditions: '',
        offerValidityHours: 0,
        offerValidityMinutes: 0,
        comments: '',
        isLimited: false,
      },
      tabs: [this.$t('details'), this.$t('lots')],
    }
  },
  props: { id: { type: String, default: '' }, viewType: { type: String, default: 'create' } },
  computed: {
    offerValidityInterval(): number {
      return Number(this.data.offerValidityHours) * 60 + Number(this.data.offerValidityMinutes)
    },
  },
  methods: {
    async fetchItem(): Promise<void> {
      const loader = this.$loading.show()
      try {
        const { data } = await TenderService.find(this.id)
        Object.assign(this.data, data.data.procurement)
        if (data.data.procurement.creator)
          this.creatorName = `${data.data.procurement.creator.firstName} ${data.data.procurement.creator.lastName}`
      } finally {
        loader.hide()
      }
    },
    async redirectBack(): Promise<void> {
      await this.$router.push({ name: this.data.type === 'sell' ? 'SellingTenders' : 'BuyingTenders' })
    },
    async update(): Promise<void> {
      const loader = this.$loading.show()
      try {
        const response = await TenderService.update(this.id, {
          ...this.data,
          offerValidityInterval: this.offerValidityInterval,
        })
        if (response) {
          this.$toast.success(response.data.message)
          await this.redirectBack()
        }
      } catch (err) {
        if (err.response.data) {
          this.errors.value = err.response.data.errors || {}
          this.$toast.error(errorMessages(err.response.data.message))
          return
        }
        this.$toast.error(this.$t('somethingWentWrong') as string)
      } finally {
        loader.hide()
      }
    },
    async add(): Promise<void> {
      const loader = this.$loading.show()
      try {
        const tenderData = {
          ...this.data,
          offerValidityInterval: this.offerValidityInterval,
        }
        console.log('tenderData', tenderData)
        const response = await TenderService.create(tenderData)
        if (response) {
          this.$toast.success(response.data.message)
          await this.redirectBack()
        }
      } catch (err) {
        if (err.response.data) {
          this.errors.value = err.response.data.errors || {}
          this.$toast.error(errorMessages(err.response.data.message))
          return
        }
        this.$toast.error(this.$t('somethingWentWrong') as string)
      } finally {
        loader.hide()
      }
    },
    addOrUpdate() {
      if (this.id) this.update()
      else this.add()
    },
  },
  mounted() {
    if (this.id) this.fetchItem()
  },
})
